import React from 'react'
import { withPrefix } from 'gatsby'
import Fade from 'react-reveal/Fade'

import { PHOTOS } from '~/utils/config'
import { Photo, PhotoList, Wrap } from './styled'

const PhotoComponent = () => {
  return (
    <Wrap>
      <PhotoList>
        <Fade top cascade distance="60px">
          <div>
            {
              PHOTOS.map((photo) => (
                <Photo key={photo.src}>
                  <img src={withPrefix(photo.src)} alt={photo.alt} />
                </Photo>
              ))
            }
          </div>
        </Fade>
      </PhotoList>
    </Wrap>
  )
}

export default PhotoComponent
