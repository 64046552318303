import React from 'react';
import { Label, Wrap } from './styled'
import CarouselComponent from './Carousel'

const InterviewWrap = () => {
  return (
    <Wrap>
      <Label>interview</Label>
      <CarouselComponent />
    </Wrap>
  )
}

export default InterviewWrap